// const supportsContainerQueries = "container" in document.documentElement.style;
// if (!supportsContainerQueries) {
//   import("container-query-polyfill");
// }

// import Alpine from 'alpinejs';
// window.Alpine = Alpine;
// Alpine.start();

import * as basicLightbox from 'basiclightbox';

window.addEventListener('load', function() {
  document.body.classList.add("loaded");
});

/*
    NAVBAR TOGGLE
*/

// const navbarToggle = document.querySelector("#nav-toggle");
// const navMenu = document.querySelector("#nav-links");
// let isNavbarExpanded = navbarToggle.getAttribute("aria-expanded") === "true";

// const toggleNavbarVisibility = () => {
//   isNavbarExpanded = !isNavbarExpanded;
//   navbarToggle.setAttribute("aria-expanded", isNavbarExpanded);
// };

// navbarToggle.addEventListener("click", toggleNavbarVisibility);

// document.addEventListener("click", (e) => {
//   if (
//     isNavbarExpanded &&
//     !navMenu.contains(e.target) &&
//     e.target !== navbarToggle
//   ) {
//     toggleNavbarVisibility();
//   }
// });

/*
    STUFF WITH DATES TO UPDATE DOM FOR EXPOS
*/

const start = document.body.dataset.start
  ? new Date(document.body.dataset.start)
  : undefined;
const end = document.body.dataset.end
  ? new Date(document.body.dataset.end)
  : undefined;
const now = new Date(new Date().setHours(0, 0, 0));

const checkDate = () => {
  // 0: past
  // 1: current
  // 2: coming
  if (!start || !end) {
    return;
  }

  const dateMsg = {};

  // Add month / year if not same as enddate
  const startDayOptions = () => {
    let options = { day: "numeric" };
    if (start.getMonth() !== end.getMonth()) {
      options = { day: "numeric", month: "long" };
    }
    if (start.getFullYear() !== end.getFullYear()) {
      options = { day: "numeric", month: "long", year: "numeric" };
    }
    return options;
  };

  const startDayOptionsLong = () => {
    let options = { weekday: "long", day: "numeric" };
    if (start.getMonth() !== end.getMonth()) {
      options = { weekday: "long", day: "numeric", month: "long" };
    }
    if (start.getFullYear() !== end.getFullYear()) {
      options = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      };
    }
    return options;
  };

  // PAST DATES
  if (now > end) {
    const startDay = new Intl.DateTimeFormat("fr-FR", startDayOptions()).format(
      start
    );
    const endDay = new Intl.DateTimeFormat("fr-FR", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(end);

    dateMsg.status = "past";
    dateMsg.dateline = `Du ${startDay} au ${endDay}`;
  }

  // CURRENT DATES
  if (now >= start && now <= end) {
    dateMsg.status = "running";
    dateMsg.dateline = `Jusqu'au ${new Intl.DateTimeFormat("fr-FR", {
      weekday: "long",
      day: "numeric",
      month: "long",
    }).format(end)}`;
  }

  // COMING DATES
  if (now < start) {
    let startDay = new Intl.DateTimeFormat("fr-FR", startDayOptionsLong()).format(
      start
    );

    dateMsg.status = "coming";
    dateMsg.dateline = `Du ${startDay} au ${new Intl.DateTimeFormat("fr-FR", {
      weekday: "long",
      day: "numeric",
      month: "long",
    }).format(end)}`;
  }

  return dateMsg;
};

const dateMsg = checkDate();

const setDateline = () => {
  const dateline = document.querySelector("#dateline");
  if (!dateline) return;
  dateline.innerHTML = dateMsg.dateline;
  if (dateMsg.status !== "past") {
    dateline.classList.add("accent");
  } else {
    dateline.classList.add("muted");
  }
};

["home", "expositions"].forEach((expo) => {
  if (document.body.dataset.template === expo) {
    document.body.dataset.status = dateMsg.status;
    if (dateMsg.status === "past") {
      dateline.innerHTML = "Dernière exposition";
      dateline.classList.add("accent");
    } else {
      setDateline();
    }
  }
});

if (document.body.dataset.template === "exposition") {
  document.body.dataset.status = dateMsg.status;
  setDateline();
}
